import Stack from '@mui/material/Stack';

import {config} from '../../../config';
import {PageLoader} from '../../common/components/loader/PageLoader';
import {OnBreakPopup} from '../components/OnBreakPopup';
import {ReviewStatistics} from '../components/ReviewStatistics';
import {StartReviewingButton} from '../components/StartReviewingButton';
import {useEventReviewContext} from '../contexts/EventReviewContext';

export default function EarlyWarningEvents() {
  const {showOnBreakModal, setShowOnBreakModal, behaviours} =
    useEventReviewContext();
  if (behaviours?.length === 0 || behaviours == null) {
    return <PageLoader label="Loading your shortcuts..." />;
  }

  return (
    <Stack m={config.theme.gutterSpacing} overflow="auto">
      <StartReviewingButton />
      <ReviewStatistics />
      <OnBreakPopup
        open={showOnBreakModal}
        onClose={() => {
          setShowOnBreakModal(false);
        }}
      />
    </Stack>
  );
}
