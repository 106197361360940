import {useMemo} from 'react';

import {DateTime} from 'luxon';

import {type EventDetailsData} from './useEventFeedback';
import {useEventReviewContext} from '../contexts/EventReviewContext';
import {type WebSocketHook} from '../WebsocketTypes';

interface EventData {
  id?: string;
  driverFirstName?: string;
  driverLastName?: string;
  creationDate?: string;
  recordDateUTC?: string;
  speed?: number;
  erSerialNumber?: string;
}

const formatDateTime = (dateString?: string) => {
  if (dateString == null) return 'Unknown';
  const date = DateTime.fromISO(dateString);
  return date.isValid ? date.toFormat('dd/MM/yyyy HH:mm') : 'Unknown';
};

const formatDriverName = (firstName?: string, lastName?: string): string => {
  const trimmedFirst = firstName?.trim() ?? '';
  const trimmedLast = lastName?.trim() ?? '';

  if ((trimmedFirst + trimmedLast).trim() === '') return 'Unknown';
  return `${trimmedFirst} ${trimmedLast}`.trim();
};

const calculateLeadTime = (recordDate?: string) => {
  if (recordDate == null) return null;

  const recorded = DateTime.fromISO(recordDate);

  if (!recorded.isValid) return null;

  const now = DateTime.now();
  const leadTimeDuration = now.diff(recorded, [
    'days',
    'hours',
    'minutes',
    'seconds',
  ]);

  return leadTimeDuration.toObject();
};

const formatSpeed = (speed?: number): string => {
  if (speed === undefined || Number.isNaN(speed)) return 'Unknown';
  return `${Math.floor(speed)}km/h`;
};

const useActiveEventData = (webSocket: WebSocketHook) => {
  return useMemo(() => {
    const activeEvent: EventData = webSocket.events[0]?.data.data ?? {};

    return {
      event: activeEvent.id ?? 'Unknown',
      driver: formatDriverName(
        activeEvent.driverFirstName,
        activeEvent.driverLastName,
      ),
      creationDate: formatDateTime(activeEvent.creationDate),
      recordedDate: formatDateTime(activeEvent.recordDateUTC),
      speed: formatSpeed(activeEvent.speed),
      serial: activeEvent.erSerialNumber ?? 'Unknown',
      leadTime: calculateLeadTime(activeEvent.recordDateUTC),
    };
  }, [webSocket]);
};

const useOrganisationData = (eventDetailsData?: EventDetailsData) => {
  return useMemo(
    () => ({
      org: eventDetailsData?.group?.fullPath?.split('\\')[0] ?? 'Unknown',
      orgGroupName:
        eventDetailsData?.group?.organisationGroup?.name ?? 'Unknown',
      vehicleRegistration:
        eventDetailsData?.vehicle?.registrationNumber ?? 'Unknown',
      eventTrigger: eventDetailsData?.trigger?.name ?? 'Unknown',
    }),
    [
      eventDetailsData?.group?.fullPath,
      eventDetailsData?.group?.organisationGroup?.name,
      eventDetailsData?.trigger?.name,
      eventDetailsData?.vehicle?.registrationNumber,
    ],
  );
};

export const useEventDetails = ({
  eventDetailsData,
}: {
  eventDetailsData?: EventDetailsData;
}) => {
  const {webSocket} = useEventReviewContext();

  const activeEventData = useActiveEventData(webSocket);
  const organisationData = useOrganisationData(eventDetailsData);

  return {
    ...activeEventData,
    ...organisationData,
  };
};
