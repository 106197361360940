import {useNavigate} from 'react-router-dom';

import {purchaseOrderApi} from '../../../api';
import {routes} from '../../../routes';
import {useSubmitForm} from '../../common/hooks/useSubmitForm';
import {type PurchaseOrderAddDetailsValues} from '../components/purchaseOrderAdd/PurchaseOrderAddDetails';

export interface AddPurchaseOrder {
  purchaseOrderNumber: string;
  contractId?: number;
  customerId?: number;
  referenceNumber?: string;
}

export const useAddPurchaseOrder = () => {
  const navigate = useNavigate();
  return useSubmitForm<PurchaseOrderAddDetailsValues>(async (values) => {
    const {purchaseOrderNumber, referenceNumber, contract, customer} = values;
    const createdPurchaseOrder = await purchaseOrderApi.apiPurchaseOrderPost({
      body: {
        purchaseOrderNumber,
        contractId: contract.value,
        customerId: customer.value,
        referenceNumber,
      },
    });
    if (createdPurchaseOrder.id != null) {
      navigate(
        routes.replaceParams(routes.purchaseOrder.purchaseOrderId, {
          purchaseOrderId: createdPurchaseOrder.id,
        }),
      );
    }
  }, []);
};
