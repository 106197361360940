import {useEffect, useMemo, useState} from 'react';
import {toast} from 'react-toastify';

import {
  type OperationalEventType,
  type Vehicle,
  type OrganisationGroupExternal,
} from '@onroadvantage/onroadvantage-api';

import {
  operationalEventTypeApi,
  organisationGroupExternalApi,
  vehicleApi,
} from '../../../api';
import {useEventReviewContext} from '../contexts/EventReviewContext';

export interface EventDetailsData {
  group: OrganisationGroupExternal;
  vehicle: Vehicle;
  trigger: OperationalEventType;
}

export const useEventFeedback = () => {
  const {webSocket} = useEventReviewContext();
  const [eventDetailsData, setEventDetailsData] = useState<EventDetailsData>();

  const {groupId, vehicleId, triggerId} = useMemo(() => {
    const eventData = webSocket.events[0]?.data.data;
    return {
      groupId: eventData?.groupId,
      vehicleId: eventData?.vehicleId,
      triggerId: eventData?.eventTriggerId,
    };
  }, [webSocket]);

  useEffect(() => {
    const fetchEventDetails = async () => {
      try {
        const [organisationResponse, vehicleResponse, triggerResponse] =
          await Promise.all([
            organisationGroupExternalApi.apiOrganisationGroupExternalOrganisationGroupExternalIdGet(
              {
                organisationGroupExternalId: groupId,
              },
            ),
            vehicleApi.apiVehicleExternalExternalIdGet({
              externalId: vehicleId,
              providerName: 'lytx',
            }),
            operationalEventTypeApi.apiOperationalEventTypeExternalReferenceExternalReferenceGet(
              {externalReference: triggerId?.toString()},
            ),
          ]);

        setEventDetailsData({
          group: organisationResponse ?? null,
          vehicle: vehicleResponse ?? null,
          trigger: triggerResponse ?? null,
        });
      } catch {
        toast('Error fetching event details:', {type: 'error'});
      }
    };

    if (groupId != null && vehicleId != null && triggerId != null) {
      void fetchEventDetails();
    }
  }, [groupId, vehicleId, triggerId]);

  return {
    eventDetailsData,
  };
};
