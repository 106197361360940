import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export function RangeSelector() {
  return (
    <Box
      mt="12px"
      position="absolute"
      display="flex"
      alignItems="center"
      right="16px"
      height="24px"
      bgcolor="#BFBFBF"
      borderRadius="8px"
      p="4px"
    >
      <Typography color="#FFFFFF" fontSize="10px">
        24 Hours
      </Typography>
    </Box>
  );
}
