import React, {useRef, useMemo} from 'react';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {type BehaviourOutcomeCalculate} from '@onroadvantage/onroadvantage-api';

export function BehaviourOutcomesChipList({
  outcomes,
}: {
  outcomes?: BehaviourOutcomeCalculate[];
}): React.ReactElement | null {
  const containerRef = useRef<HTMLDivElement>(null);

  const chips = useMemo(
    () =>
      outcomes?.map((outcome) => (
        <Chip
          label={outcome.name}
          key={outcome.id}
          variant="filled"
          sx={{minHeight: '40px'}}
        />
      )),
    [outcomes],
  );

  return (
    <Stack
      display="flex"
      bgcolor="#FFFFFF"
      mt="16px"
      borderRadius="16px"
      flexWrap="wrap"
    >
      <div
        style={{
          width: '100%',
          backgroundColor: '#EFEFEF',
          alignItems: 'center',
          display: 'flex',
          columnGap: '40px',
          padding: '8px 20px',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        }}
      >
        <Typography fontSize="16px" fontWeight={600}>
          BEHAVIOUR AND OBSERVATION OUTCOMES
        </Typography>
      </div>
      <Stack
        direction="row"
        spacing={1}
        ref={containerRef}
        p="12px"
        flexWrap="wrap"
        rowGap="12px"
        minHeight="64px"
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {chips}
      </Stack>
    </Stack>
  );
}
