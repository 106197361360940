import {useMemo} from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {VantageBooleanField} from '../../../common/components/form/VantageBooleanField';
import {useEventReviewContext} from '../../contexts/EventReviewContext';
import {
  type ExtendedBehaviour,
  useCheckUnselectNoIssue,
} from '../../hooks/useCheckUnselectNoIssue';
import {type CategoryWithData} from '../../pages/EventFeedback';

export function Behaviours({
  behaviours,
}: {
  behaviours: Array<CategoryWithData<ExtendedBehaviour>>;
}) {
  const {formRef} = useEventReviewContext();

  useCheckUnselectNoIssue({
    formRef,
    behaviours: behaviours.flatMap(([, items]) => items),
  });

  const formattedContent = useMemo(() => {
    const {length} = behaviours;

    if (length === 0) {
      return null;
    }

    if (length === 1) {
      const [category, behaviourData] = behaviours[0];
      return (
        <Grid item xs={6}>
          <Typography fontSize={16} fontWeight={700} my={0.5}>
            {category}
          </Typography>
          {behaviourData.map((behaviour) => {
            return (
              <VantageBooleanField
                key={behaviour.id}
                value={behaviour.id}
                name={
                  behaviour.name != null
                    ? `__lookupKey: ${behaviour.shortcut}__ ${behaviour.name}`
                    : ''
                }
                label={`${behaviour.name}`}
                type="checkbox"
                formik
              />
            );
          })}
        </Grid>
      );
    }

    return (
      <>
        <Grid item xs={6}>
          {behaviours.slice(0, 2).map(([category, behaviourData]) => {
            return (
              <>
                <Typography fontSize={16} fontWeight={700} my={0.5}>
                  {category}
                </Typography>
                {behaviourData.map((behaviour) => {
                  return (
                    <VantageBooleanField
                      key={behaviour.id}
                      name={
                        behaviour.name != null
                          ? `__lookupKey: ${behaviour.shortcut}__${
                              behaviour.name
                            }`
                          : ''
                      }
                      label={`${behaviour.name}`}
                      type="checkbox"
                      formik
                    />
                  );
                })}
              </>
            );
          })}
        </Grid>
        <>
          {behaviours.slice(2).map(([category, behaviourData]) => {
            return (
              <Grid item xs={6} key={category}>
                {category.toLocaleLowerCase() !== 'hidden' && (
                  <Typography fontSize={16} fontWeight={700} my={0.5}>
                    {category}
                  </Typography>
                )}
                {behaviourData.map((behaviour) => {
                  return (
                    <StyledCheckbox
                      key={behaviour.id}
                      name={
                        behaviour.name != null
                          ? `__lookupKey: ${behaviour.shortcut}__${
                              behaviour.name
                            }`
                          : ''
                      }
                      label={`${behaviour.name}`}
                      type="checkbox"
                      formik
                      $hidden={
                        behaviour?.name?.trim() === 'Faulty Clip' ||
                        behaviour?.name?.trim() === 'No Issue'
                      }
                    />
                  );
                })}
              </Grid>
            );
          })}
        </>
      </>
    );
  }, [behaviours]);

  return formattedContent != null ? (
    <Grid item xs={6}>
      <Stack bgcolor="#EFEFEF" borderRadius="16px" p="12px" height="100%">
        <Typography fontSize={16} fontWeight={700} mb="12px">
          BEHAVIOURS
        </Typography>
        <Grid container spacing={4}>
          {formattedContent}
        </Grid>
      </Stack>
    </Grid>
  ) : null;
}

const StyledCheckbox = styled(VantageBooleanField)<{$hidden: boolean}>(({
  $hidden,
}) => {
  if ($hidden) {
    return {
      height: 0,
      pointerEvents: 'none',
      visibility: 'hidden',
      '+ .MuiTypography-root': {
        visibility: 'hidden',
      },
    };
  }
  return {};
});
