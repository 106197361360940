import {type Behaviour} from '@onroadvantage/onroadvantage-api';

export interface Data extends Behaviour {
  shortcut?: string;
  name?: string;
}

export type TableLookup = Record<string, Data>;

export type CategoryWithData<T> = [string, T[]];

export const useAddShortcutsToFormValues = (
  behaviourData: Array<CategoryWithData<Data>>,
  observationData: Array<CategoryWithData<Data>>,
) => {
  const processData = (
    data: Array<CategoryWithData<Data>>,
  ): [Array<CategoryWithData<Data>>, TableLookup] => {
    const lookupTable: TableLookup = {};
    const categorizedData = data.map(([categoryName, items]) => {
      const shortcutPrefix = categoryName.charAt(0).toLowerCase();
      lookupTable.lc = {shortcut: 'lc', name: 'Late Clip'};

      const updatedItems = items.map((item, index) => {
        if (item?.name?.trim() === 'Faulty Clip') {
          const shortcut = 'fc';
          const updatedItem = {...item, shortcut};
          lookupTable[shortcut] = updatedItem;
          return updatedItem;
        }
        if (item?.name?.trim() === 'No Issue') {
          const shortcut = 'n';
          const updatedItem = {...item, shortcut};
          lookupTable[shortcut] = updatedItem;
          return updatedItem;
        }
        const shortcut = `${shortcutPrefix}${index + 1}`;
        const updatedItem = {...item, shortcut};
        lookupTable[shortcut] = updatedItem;
        return updatedItem;
      });
      return [categoryName, updatedItems] as CategoryWithData<Data>;
    });
    return [categorizedData, lookupTable];
  };

  const [categorizedBehaviorsWithShortcuts, behaviorLookupTable] =
    processData(behaviourData);
  const [categorizedObservationsWithShortcuts, observationLookupTable] =
    processData(observationData);

  return {
    categorizedBehaviorsWithShortcuts,
    behaviorLookupTable,
    categorizedObservationsWithShortcuts,
    observationLookupTable,
  };
};
