import {useState, useEffect, type RefObject} from 'react';

import {type FormikProps} from 'formik';

export const useFormValues = (
  formRef: RefObject<FormikProps<Record<string, boolean>>>,
) => {
  const [formValues, setFormValues] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const checkForUpdates = () => {
      const currentValues = formRef.current?.values ?? {};
      setFormValues((prevValues) => {
        if (JSON.stringify(prevValues) !== JSON.stringify(currentValues)) {
          return {...currentValues};
        }
        return prevValues;
      });
    };

    const intervalId = setInterval(checkForUpdates, 500);
    return () => {
      clearInterval(intervalId);
    };
  }, [formRef]);

  return formValues;
};
