import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import LocalCafeOutlinedIcon from '@mui/icons-material/LocalCafeOutlined';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import {keyframes, styled} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import {config} from '../../../config';
import {useEventReviewBottomBar} from '../hooks/useEventReviewBottomBar';

export function EventReviewBottomBar() {
  const {
    handleLateClip,
    navigateBackToVideo,
    navigationDrawerOpen,
    handleNavigateBack,
    searchParams,
    handleNoIssue,
    noIssueSelected,
    handleSessionPause,
    noIssueDisabled,
    nextEventAvailable,
    feedbackFormRef,
    handleFaultyClip,
    faultyClipSelected,
    lateClipSelected,
    nextDisabled,
  } = useEventReviewBottomBar();

  return (
    <StyledTopBar navigationDrawerOpen={navigationDrawerOpen}>
      <IconButton
        sx={{
          width: '32px',
          height: '32px',
          borderRadius: '100%',
          backgroundColor: '#CCCCCC',
        }}
        onClick={handleNavigateBack}
      >
        <KeyboardBackspaceIcon sx={{color: '#4C4C4C'}} />
      </IconButton>
      <IconButton
        sx={{
          width: '32px',
          height: '32px',
          borderRadius: '100%',
          backgroundColor: '#CCCCCC',
        }}
        onClick={navigateBackToVideo}
      >
        <PlayArrowIcon sx={{color: '#4C4C4C'}} />
      </IconButton>

      <SelectedFormValueButton
        $selected={lateClipSelected}
        onClick={handleLateClip}
      >
        <Typography fontSize="12px" fontWeight={700}>
          LATE CLIP
        </Typography>
      </SelectedFormValueButton>

      <SelectedFormValueButton
        $selected={faultyClipSelected}
        onClick={handleFaultyClip}
      >
        <Typography fontSize="12px" fontWeight={700}>
          FAULTY CLIP
        </Typography>
      </SelectedFormValueButton>

      <SelectedFormValueButton
        onClick={() => {
          handleNoIssue();
        }}
        $selected={noIssueSelected}
        disabled={noIssueDisabled}
      >
        <Typography fontSize="12px" fontWeight={700}>
          NO ISSUE
        </Typography>
      </SelectedFormValueButton>

      <div
        style={{
          position: 'absolute',
          right: '48px',
          display: 'flex',
          columnGap: '50px',
          alignItems: 'center',
        }}
      >
        <Button
          sx={{
            backgroundColor: '#333333',
            borderRadius: '16px',
            p: '3px 12px',
            height: '32px',
            marginLeft: 'auto',
            visibility:
              searchParams.get('notifications') != null ? 'visible' : 'hidden',
          }}
          onClick={handleSessionPause}
        >
          <LocalCafeOutlinedIcon sx={{color: '#FDFBE9', height: '16px'}} />
          <Typography fontSize="12px" color="#FDFBE9" fontWeight={700}>
            SUBMIT AND TAKE A BREAK
          </Typography>
        </Button>
        <Tooltip
          title={
            <Typography>
              {nextEventAvailable
                ? 'Next Event Available'
                : 'Next Event Not Yet Available'}
            </Typography>
          }
        >
          <NextAvailable
            show={nextEventAvailable}
            onClick={async () => {
              await feedbackFormRef?.current?.submitForm();
            }}
            disabled={nextDisabled}
          >
            <ArrowForwardIcon
              sx={{
                color: 'white',
                height: '24px',
                width: '24px',
              }}
            />
          </NextAvailable>
        </Tooltip>
      </div>
    </StyledTopBar>
  );
}

const StyledTopBar = styled(Stack)(
  ({navigationDrawerOpen}: {navigationDrawerOpen: boolean}) => ({
    position: 'fixed',
    bottom: 0,
    width: !navigationDrawerOpen ? '100%' : 'calc(100% - 255px)',
    backgroundColor: '#EFEFEF',
    color: '#4C4C4C',
    height: '54px',
    padding: '10px 48px',
    borderRadius: '0 0 16px 16px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: '10px',
    fontWeight: 700,
    transition: 'width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    '& .MuiChip-root, button': {
      cursor: 'pointer',
      color: '#4C4C4C',
    },
  }),
);

const EventAvailablePulse = keyframes`
0% {
    -moz-box-shadow: 0 0 0 0 rgba(242, 103, 34, 0.6);
    box-shadow: 0 0 0 0 rgba(242, 103, 34, 0.6);
}
70% {
    -moz-box-shadow: 0 0 0 10px rgba(242, 103, 34, 0);
    box-shadow: 0 0 0 10px rgba(242, 103, 34, 0);
}
100% {
    -moz-box-shadow: 0 0 0 0 rgba(242, 103, 34, 0);
    box-shadow: 0 0 0 0 rgba(242, 103, 34, 0);
}
`;

const NextAvailable = styled(IconButton)(({show}: {show: boolean}) => ({
  backgroundColor: show ? config.theme.primaryDefault : '#4c4c4c',
  ':hover': {
    backgroundColor: show ? config.theme.primaryDefault : '#4c4c4c',
  },
  '&.Mui-disabled': {
    backgroundColor: '#4c4c4c',
  },
  transition: 'background-color 150ms ease-in-out',
  width: '32px',
  height: '32px',
  borderRadius: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  animation: show ? `${EventAvailablePulse} 1.5s ease-in-out 3` : 'none',
}));

const SelectedFormValueButton = styled(Button)(
  ({$selected}: {$selected: boolean}) => ({
    backgroundColor: $selected ? config.theme.primaryDefault : '#CCCCCC',

    ' .MuiTypography-root': {
      color: $selected ? '#FFFFFF' : '#4c4c4c',
    },
    ':hover': {
      backgroundColor: $selected && config.theme.primaryDefault,
    },
    '&.Mui-disabled': {
      backgroundColor: $selected ? config.theme.primaryDefault : '#CCCCCC',
      opacity: $selected ? 1 : 0.5,
    },
    transition:
      'background-color 150ms ease-in-out, color 150ms ease-in-out, opacity 150ms ease-in-out',
  }),
);
