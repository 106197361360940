import {type EarlyWarningEvent} from '@onroadvantage/onroadvantage-api';

import {earlyWarningEventApi} from '../../../api';
import {useLoadList} from '../../common/hooks/useLoadList';

export type ExtendedEarlyWarningEvent = EarlyWarningEvent & {date: Date};
export const useEventPastEvents = ({
  vehicleRegistration,
}: {
  vehicleRegistration: string;
}) => {
  const {data} = useLoadList(async () =>
    earlyWarningEventApi.apiEarlyWarningEventGet({
      page: 1,
      perPage: 100,
      vehicleRegistrationNumber: vehicleRegistration,
      behaviourOutcomeName: 'fatigue',
    }),
  );
  const times: ExtendedEarlyWarningEvent[] =
    data?.map((d) => ({
      date: new Date(String(d?.data?.recordDateUTC)),
      ...d,
    })) ?? [];

  const getTimestampPosition = (timestamp: Date) => {
    const now = new Date();
    const timeDiff =
      timestamp.getTime() - (now.getTime() - 24 * 60 * 60 * 1000);
    const hoursDiff = timeDiff / (1000 * 60 * 60);
    return (hoursDiff / 24) * 100;
  };

  return {
    timestamps: times,
    getTimestampPosition,
  };
};
