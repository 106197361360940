import {lazy} from 'react';
import {type RouteObject} from 'react-router-dom';

import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const LazyMap = lazy(async () => import('./pages/MapViewMap'));

export const mapRoutes: RouteObject[] = [
  {
    path: '',
    element: <LazyMap />,
    loader: () => {
      document.title = 'Mapview';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Map View'}],
      });
      return null;
    },
  },
];
