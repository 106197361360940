import {useEffect, useState} from 'react';

import LocalCafeOutlinedIcon from '@mui/icons-material/LocalCafeOutlined';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

interface OnBreakPopupProps {
  open: boolean;
  onClose: () => void;
}

export function OnBreakPopup({open, onClose}: OnBreakPopupProps) {
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    let interval: number;

    if (open) {
      setSeconds(0);
      interval = setInterval(() => {
        setSeconds((prev) => prev + 1);
      }, 1000);
    }

    return () => {
      if (interval != null) clearInterval(interval);
    };
  }, [open]);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle>Enjoy your break!</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          rowGap: '12px',
        }}
      >
        <LocalCafeOutlinedIcon sx={{height: '132px', width: '132px'}} />
        <Typography fontSize="16px">
          You have paused your review session.
        </Typography>
        <Typography fontSize="16px">
          In this time, new events will not be assigned to you.
        </Typography>
        <Typography fontSize="16px">
          Resume your session when you are ready.
        </Typography>
        <Typography fontSize="16px">
          You have been on break for: {minutes.toString().padStart(2, '0')}:
          {remainingSeconds.toString().padStart(2, '0')}
        </Typography>
        <DialogActions sx={{mt: 2, width: '100%'}}>
          <Button onClick={onClose} variant="contained" fullWidth>
            <Typography fontSize="16px">Continue</Typography>
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
