import CameraAlt from '@mui/icons-material/CameraAlt';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export interface EventDetailMimicData {
  event: number | undefined;
  serial: string | undefined;
  speed: number;
  creationDate: string | undefined;
  recordedDate: string | undefined;
  org: string;
  orgGroupName: string | undefined;
  driver?: string | null;
  leadTime: {hours: number; minutes: number; seconds: number};
  vehicleRegistration: string | undefined;
  eventTrigger: string | number | undefined;
}

export function EventDetailsMimic({
  mimicObject,
}: {
  mimicObject: EventDetailMimicData;
}) {
  const {
    event,
    serial,
    speed,
    creationDate,
    recordedDate,
    org,
    orgGroupName,
    driver,
    leadTime,
    vehicleRegistration,
    eventTrigger,
  } = mimicObject;

  return (
    <Stack borderRadius="16px" bgcolor="white" mt="18px" width="100%">
      <Stack
        width="100%"
        bgcolor="#EFEFEF"
        display="flex"
        direction="row"
        alignItems="center"
        columnGap="40px"
        p="8px 20px"
        sx={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        }}
      >
        <CameraAlt />
        <Typography fontSize="16px" fontWeight={600}>
          EVENT DETAILS
        </Typography>
        <Typography fontSize="16px" fontWeight={600} ml="auto">
          {`${String(leadTime?.hours ?? '0').padStart(2, '0')}`}:
          {`${String(leadTime?.minutes ?? '0').padStart(2, '0')}`}:
          {`${String(Math.floor(leadTime?.seconds ?? 0)).padStart(2, '0')}`}
        </Typography>
      </Stack>
      <div
        style={{
          display: 'flex',
          padding: '16px 40px',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Typography fontSize="12px" marginBottom="12px">
            Organisation: {org}
          </Typography>
          <Typography fontSize="12px">Group: {orgGroupName}</Typography>
        </div>
        <div>
          <Typography fontSize="12px" marginBottom="12px">
            Event Trigger: {eventTrigger}
          </Typography>
          <Typography fontSize="12px">Event: {event}</Typography>
        </div>
        <div>
          <Typography fontSize="12px" marginBottom="12px">
            Speed: {speed}
          </Typography>
          <Typography fontSize="12px">
            Vehicle: {vehicleRegistration}
          </Typography>
        </div>
        <div>
          <Typography fontSize="12px" marginBottom="12px">
            Serial: {serial}
          </Typography>
          <Typography fontSize="12px">Driver: {driver}</Typography>
        </div>
        <div>
          <Typography fontSize="12px" marginBottom="12px">
            Created: {creationDate}
          </Typography>
          <Typography fontSize="12px">Recorded: {recordedDate}</Typography>
        </div>
      </div>
    </Stack>
  );
}
