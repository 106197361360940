import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

export function EventsPastEventsShimmer() {
  return (
    <Stack width="100%">
      <Box
        position="relative"
        height="80px"
        borderRadius="16px"
        display="flex"
        alignItems="center"
        mt="16px"
        bgcolor="#EFEFEF"
      />
    </Stack>
  );
}
