import {type RefObject, useEffect, useMemo} from 'react';
import {toast} from 'react-toastify';

import type {Behaviour} from '@onroadvantage/onroadvantage-api';
import {type FormikProps} from 'formik/dist/types';

import {useFormValues} from './useFormValues';
import {checkBehaviorTypes, processLookupObject} from '../utils/feedbackUtils';

export interface ExtendedBehaviour extends Behaviour {
  shortcut?: string;
}

export const useCheckUnselectNoIssue = ({
  formRef,
  behaviours,
}: {
  formRef: RefObject<FormikProps<Record<string, boolean>>>;
  behaviours: ExtendedBehaviour[];
}) => {
  const formValues = useFormValues(formRef);
  const cleanedKeys = useMemo(
    () => processLookupObject(formValues),
    [formValues],
  );
  const containsOnlyObservations = useMemo(
    () => checkBehaviorTypes(cleanedKeys, behaviours),
    [cleanedKeys, behaviours],
  );

  const disabledBehaviour =
    formValues['__lookupKey: n__No Issue'] &&
    Object.entries(formValues).filter(([, value]) => value).length > 1;

  useEffect(() => {
    if (disabledBehaviour && !containsOnlyObservations) {
      void formRef?.current?.setValues((current) => ({
        ...current,
        '__lookupKey: n__No Issue': false,
      }));

      toast(
        'Event no longer marked as non issue because a behaviour was selected',
        {type: 'warning'},
      );
    }

    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledBehaviour, containsOnlyObservations]);
};
