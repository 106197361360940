import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel, {type InputLabelProps} from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, {
  type SelectProps,
  type SelectChangeEvent,
} from '@mui/material/Select';
import {useFormikContext} from 'formik';

import {useFieldDisabled} from './hooks/useFieldDisabled';
import {useFieldError} from './hooks/useFieldError';

export type VantageSelectFieldProps =
  | ({formik: true} & VantageFieldStringFormikProps)
  | ({formik?: false} & VantageFieldStringBaseProps);

export function VantageSelectField({
  formik,
  ...props
}: VantageSelectFieldProps) {
  if (formik === true) {
    return <VantageSelectFieldFormik {...props} />;
  }
  return <VantageSelectFieldBase {...(props as VantageFieldStringBaseProps)} />;
}

type VantageFieldStringFormikProps = Omit<
  VantageFieldStringBaseProps,
  'value' | 'onChange'
>;

function VantageSelectFieldFormik({
  name,
  disabled = false,
  ...props
}: VantageFieldStringFormikProps) {
  const fieldDisabled = useFieldDisabled(disabled);
  const fieldError = useFieldError(name);
  const {getFieldMeta, getFieldHelpers, handleBlur} = useFormikContext();
  const {initialValue} = getFieldMeta(name);
  const {setValue} = getFieldHelpers(name);

  const handleChange = async (value: string | null) => {
    await setValue(value);
  };

  return (
    <VantageSelectFieldBase
      error={fieldError != null}
      {...props}
      helperText={fieldError}
      name={name}
      disabled={fieldDisabled}
      onChange={handleChange}
      onBlur={handleBlur}
      defaultValue={
        typeof initialValue === 'string' || initialValue === undefined
          ? initialValue
          : undefined
      }
    />
  );
}

type VantageFieldStringBaseProps = Omit<
  SelectProps<string>,
  'name' | 'onChange' | 'variant'
> & {
  name: string;
  options: string[] | readonly string[] | Array<{label: string; value: number}>;
  onChange: (value: string | null) => void;
  helperText?: string | null | undefined;
  InputLabelProps?: InputLabelProps;
};

function VantageSelectFieldBase({
  name,
  error,
  options,
  fullWidth = true,
  label,
  id,
  onChange,
  helperText,
  style,
  InputLabelProps,
  ...props
}: VantageFieldStringBaseProps) {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value);
  };

  return (
    <FormControl
      size={props.size}
      error={error}
      fullWidth={fullWidth}
      style={style}
    >
      <InputLabel
        error={error}
        id={`${id ?? name}-select-label`}
        {...InputLabelProps}
      >
        {label}
      </InputLabel>
      <Select
        error={error}
        fullWidth={fullWidth}
        id={name}
        {...props}
        variant="outlined"
        label={label}
        onChange={handleChange}
        name={name}
      >
        {options.map((option) => {
          if (typeof option === 'string') {
            return (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            );
          }
          return (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
      {helperText != null && (
        <FormHelperText error={error}>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
}
