import {useEffect, useRef} from 'react';
import {LayersControl, MapContainer, Marker, TileLayer} from 'react-leaflet';

import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import {useFormikContext} from 'formik';
import {LatLng, type LatLngBounds, type Map as LeafletMap} from 'leaflet';

import {SiteMapDrawing} from './SiteMapDrawing';
import {config} from '../../../config';
import {getNestedBorderRadius} from '../../../theme/getNestedBorderRadius';
import {InfoIcon} from '../../common/components/map/vantageMapIcons';
import {useMapDisplayStore} from '../../common/stores/mapDisplayStore';

import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet/dist/leaflet.css';
import '../../common/components/map/map.css';

export function SiteMap({bounds}: {bounds: LatLngBounds}) {
  const mapRef = useRef<LeafletMap | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const baseLayer = useMapDisplayStore((state) => state.baseLayer);
  const {getFieldMeta, getFieldHelpers} = useFormikContext();
  const {value: latitude} = getFieldMeta<number>('latitude');
  const {value: longitude} = getFieldMeta<number>('longitude');
  const {setValue: setLatitude} = getFieldHelpers<number>('latitude');
  const {setValue: setLongitude} = getFieldHelpers<number>('longitude');
  const {value: geofencesGeoJson} = getFieldMeta<L.GeoJSON | null>(
    'geofencesGeoJson',
  );

  useEffect(() => {
    const container = containerRef?.current;
    const resizeObserver = new ResizeObserver(() => {
      if (mapRef.current != null) {
        mapRef.current.invalidateSize({animate: true});
      }
    });

    if (container != null) {
      resizeObserver.observe(container);
    }

    return () => {
      if (container != null) {
        resizeObserver.unobserve(container);
      }
    };
  }, [containerRef]);

  return (
    <StyledMapContainer ref={containerRef}>
      <MapContainer
        zoomControl={false}
        doubleClickZoom={false}
        touchZoom={false}
        markerZoomAnimation
        className="vantage-map"
        ref={mapRef}
        center={[latitude, longitude]}
        bounds={bounds}
      >
        <LayersControl>
          <LayersControl.BaseLayer
            name="Hybrid"
            checked={baseLayer === 'Hybrid'}
          >
            <TileLayer
              url={config.mapResources.hybridTilingUrl}
              tileSize={512}
              zoomOffset={-1}
              maxZoom={18}
              updateWhenIdle
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer
            name="Streets"
            checked={baseLayer === 'Streets'}
          >
            <TileLayer
              url={config.mapResources.streetsTilingUrl}
              tileSize={512}
              zoomOffset={-1}
              maxZoom={18}
              updateWhenIdle
            />
          </LayersControl.BaseLayer>
        </LayersControl>
        <SiteMapDrawing />
        <Marker
          icon={InfoIcon}
          draggable
          eventHandlers={{
            dragend: (event) => {
              const markerLatLng = event.target.getLatLng() as LatLng;
              if (geofencesGeoJson == null) {
                void setLatitude(markerLatLng.lat);
                void setLongitude(markerLatLng.lng);
                return;
              }

              const geofenceBounds = geofencesGeoJson.getBounds();

              const markerContained = geofenceBounds.contains(markerLatLng);

              if (markerContained) {
                void setLatitude(markerLatLng.lat);
                void setLongitude(markerLatLng.lng);
              } else {
                const currentLatLng = new LatLng(latitude, longitude);

                const currentMarkerContained =
                  geofenceBounds.contains(currentLatLng);
                const center = geofenceBounds.getCenter();

                if (currentMarkerContained) {
                  void setLatitude(latitude);
                  void setLongitude(longitude);
                } else {
                  void setLatitude(center.lat);
                  void setLongitude(center.lng);
                }
              }
            },
          }}
          position={[latitude, longitude]}
        />
      </MapContainer>
    </StyledMapContainer>
  );
}

export const StyledMapContainer = styled(Stack)(({theme}) => ({
  borderRadius: getNestedBorderRadius(theme, 1),
  position: 'relative',
  overflow: 'hidden',
  flex: 1,
}));
