import Stack from '@mui/material/Stack';
import {type EarlyWarningEventBehaviourOutcome} from '@onroadvantage/onroadvantage-api';

import {config} from '../../../../config';
import {type ExtendedEarlyWarningEvent} from '../../hooks/useEventPastEvents';

export function TimestampDisplay({
  timestamps,
  getTimestampPosition,
}: {
  timestamps: ExtendedEarlyWarningEvent[];
  getTimestampPosition: (timestamp: Date) => number;
}) {
  const now = new Date();
  const filteredTimestamps = timestamps.filter((timestamp) => {
    const hoursDiff =
      (now.getTime() - timestamp.date.getTime()) / (1000 * 60 * 60);
    return hoursDiff <= 24 && hoursDiff >= 0;
  });

  const hasFatigueCritical = (
    behaviourOutcomes?: EarlyWarningEventBehaviourOutcome[],
  ) => {
    return (
      behaviourOutcomes?.some(
        (outcome) => outcome.name === 'Fatigue - Critical',
      ) ?? false
    );
  };

  return (
    filteredTimestamps.length > 0 &&
    filteredTimestamps.map((timestamp) => {
      const isCritical = hasFatigueCritical(
        timestamp.reviewData?.behaviourOutcomes,
      );
      return (
        <Stack
          key={timestamp.id}
          position="absolute"
          top="50%"
          width="16px"
          height="16px"
          borderRadius="50%"
          bgcolor={isCritical ? '#F22222' : config.theme.primaryDefault}
          left={`${getTimestampPosition(timestamp.date)}%`}
          sx={{
            transform: 'translateY(-50%)',
          }}
        />
      );
    })
  );
}
