import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {RangeSelector} from './RangeSelector';
import {EventsPastEventsShimmer} from './shimmers/EventPastEventsShimmer';
import {TimestampDisplay} from './TimestampDisplay';
import {useEventPastEvents} from '../../hooks/useEventPastEvents';

export function EventPastEvents({
  vehicleRegistration,
}: {
  vehicleRegistration?: string;
}) {
  if (vehicleRegistration == null) {
    return <EventsPastEventsShimmer />;
  }

  return (
    <EventsPastEventsComponent vehicleRegistration={vehicleRegistration} />
  );
}

function EventsPastEventsComponent({
  vehicleRegistration,
}: {
  vehicleRegistration: string;
}) {
  const {getTimestampPosition, timestamps} = useEventPastEvents({
    vehicleRegistration,
  });

  return (
    <Stack width="100%">
      <Box
        position="relative"
        height="80px"
        borderRadius="16px"
        display="flex"
        alignItems="center"
        mt="16px"
        bgcolor="#EFEFEF"
      >
        <Stack width="100%" padding="0 16px">
          <Stack
            width="100%"
            height="8px"
            borderRadius="8px"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            padding="0 16px"
            bgcolor="#808080"
          />
          <Typography
            sx={{
              position: 'absolute',
              right: 0,
              marginRight: '16px',
              fontWeight: 500,
              top: '10px',
              fontSize: '14px',
              color: '#4D4D4D',
            }}
          >
            VEHICLE FATIGUE HISTORY
          </Typography>
          <RangeSelector />
        </Stack>
        <TimestampDisplay
          timestamps={timestamps}
          getTimestampPosition={getTimestampPosition}
        />
      </Box>
    </Stack>
  );
}
