import {useCallback} from 'react';
import {useNavigate} from 'react-router-dom';

import Button from '@mui/material/Button';
import {styled} from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import {routes} from '../../../routes';
import {useEventStore} from '../../common/stores/eventStore';
import {useNavigationStore} from '../../common/stores/navigationStore';
import {useEventReviewContext} from '../contexts/EventReviewContext';

export function StartReviewingButton() {
  const {webSocket} = useEventReviewContext();
  const toggleNavigationsDrawer = useNavigationStore(
    (state) => state.toggleNavigationsDrawer,
  );
  const closeEventsDrawer = useEventStore((state) => state.closeEventsDrawer);
  const navigate = useNavigate();
  const handleStartReview = useCallback(() => {
    webSocket.connect();
    toggleNavigationsDrawer(false);
    closeEventsDrawer();
    navigate(routes.eventReview.review);
  }, [navigate, closeEventsDrawer, toggleNavigationsDrawer, webSocket]);

  return (
    <StyledStartReviewingButton onClick={handleStartReview} variant="contained">
      <Typography fontSize="1rem" fontWeight={800}>
        START REVIEWING
      </Typography>
    </StyledStartReviewingButton>
  );
}

const StyledStartReviewingButton = styled(Button)`
  border-radius: 8px;
  min-height: 50px;
  margin-bottom: 40px;
`;
