import {type MouseEvent} from 'react';

import {Plugin, Template, TemplatePlaceholder} from '@devexpress/dx-react-core';
import RefreshIcon from '@mui/icons-material/RefreshRounded';
import IconButton from '@mui/material/IconButton';

export interface TableToolbarRefreshButtonProps {
  loading?: boolean;
  onRefresh?: (event: MouseEvent<HTMLButtonElement>) => void;
}

/** adds a loading indicator to the DevExpress Toolbar */
export function TableToolbarRefreshButton({
  loading,
  onRefresh,
}: TableToolbarRefreshButtonProps) {
  return (
    <Plugin dependencies={[{name: 'Toolbar'}]} name="ToolbarMapsButon">
      <Template name="toolbarContent">
        <TemplatePlaceholder />
        <IconButton disabled={loading} onClick={onRefresh}>
          <RefreshIcon />
        </IconButton>
      </Template>
    </Plugin>
  );
}
