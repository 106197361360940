import {useMemo} from 'react';

import {type Behaviour} from '@onroadvantage/onroadvantage-api';

import {useAddShortcutsToFormValues} from './useAddShortcutsToFormValues';
import {categorizeItems} from '../utils/feedbackUtils';

export const useGenerateLookupTables = ({
  behaviours,
}: {
  behaviours?: Behaviour[];
}) => {
  const categorizedData = useMemo(() => {
    const categorizedBehaviours = categorizeItems(behaviours, 'behaviour');
    const categorizedObservations = categorizeItems(behaviours, 'observation');

    return {
      categorizedBehaviours,
      categorizedObservations,
      behaviourList: Object.entries(categorizedBehaviours),
      observationList: Object.entries(categorizedObservations),
    };
  }, [behaviours]);

  const {
    categorizedBehaviorsWithShortcuts,
    categorizedObservationsWithShortcuts,
    behaviorLookupTable,
    observationLookupTable,
  } = useAddShortcutsToFormValues(
    categorizedData.behaviourList,
    categorizedData.observationList,
  );

  return {
    categorizedBehaviorsWithShortcuts,
    categorizedObservationsWithShortcuts,
    behaviorLookupTable,
    observationLookupTable,
    combinedLookup: {
      ...behaviorLookupTable,
      ...observationLookupTable,
    },
  };
};

export type LookupTablesResult = ReturnType<typeof useGenerateLookupTables>;
