import MailIcon from '@mui/icons-material/Mail';
import MessageIcon from '@mui/icons-material/Message';
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import {type Contact} from '@onroadvantage/onroadvantage-api';

interface NotificationRowProps
  extends Pick<Contact, 'name' | 'cellphoneNumber' | 'email'> {
  onCallClicked: (contactName: string) => void;
}

export function NotificatioContactRow({
  name,
  cellphoneNumber,
  email,
  onCallClicked,
}: NotificationRowProps) {
  return (
    <TableRow>
      <TableCell>
        <Typography variant="subtitle1">{name}</Typography>
      </TableCell>
      <TableCell>{cellphoneNumber}</TableCell>
      <TableCell>{email}</TableCell>
      <TableCell align="right">
        <Link
          href={`tel:${cellphoneNumber}`}
          aria-label="phone"
          onClick={() => {
            onCallClicked(name);
          }}
          sx={{
            color: 'rgba(0, 0, 0, 0.54)',
            padding: '5px',
            position: 'relative',
            top: '8px',
          }}
        >
          <PhoneIcon />
        </Link>
        <IconButton size="small" aria-label="email">
          <MailIcon />
        </IconButton>
        <IconButton size="small" aria-label="chat">
          <MessageIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
