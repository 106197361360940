import Stack from '@mui/material/Stack';

export function TimelineCounter({
  duration,
  currentTime,
}: {
  duration: number;
  currentTime: number;
}) {
  return (
    <>
      <Stack>
        00:
        {(Math.round(currentTime) > 9
          ? Math.round(currentTime)
          : `0${Math.round(currentTime)}`) ?? '--'}
      </Stack>
      <Stack>
        00:
        {(Math.round(duration) > 9
          ? Math.round(duration)
          : `0${Math.round(duration)}`) ?? '--'}
      </Stack>
    </>
  );
}
