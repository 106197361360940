export const routes = {
  getRelativePath: (route: string, baseRoute: string) =>
    route.replace(`${baseRoute}/`, ''),
  replaceParams: (route: string, params: Record<string, string | number>) => {
    return Object.entries(params).reduce((updatedRoute, [key, value]) => {
      return updatedRoute.replace(new RegExp(`:${key}`, 'g'), value.toString());
    }, route);
  },
  addQueryParams: (route: string, params: Record<string, string>) => {
    const searchParams = new URLSearchParams(params);
    return `${route}?${searchParams.toString()}`;
  },
  audience: {
    base: '/audience',
    list: '/audience/list',
    add: '/audience/add',
    contactId: '/audience/:contactId',
    behaviourAdd: '/audience/behaviour/add',
  },
  audienceGroup: {
    base: '/audienceGroup',
    list: '/audienceGroup/list',
    add: '/audienceGroup/add',
    audienceGroupId: '/audienceGroup/:audienceGroupId',
  },
  login: '/login',
  auth: {
    base: '/auth',
    forgotPassword: '/auth/forgotPassword',
    resetPassword: '/auth/password_reset',
    login: '/auth/login',
    redirect: '/auth/redirect',
    oidc: '/login/oidc',
    oidcCallback: '/login/oidc/callback',
  },
  broadcast: {
    base: '/broadcast',
    singleMessage: '/broadcast/singleMessage',
    vantageMobile: '/broadcast/vantageMobile',
    list: '/broadcast/list',
    broadcastId: '/broadcast/:broadcastId',
  },
  clientApiDocs: '/clientapidocs',
  contract: {
    base: '/contract',
    list: '/contract/list',
    add: '/contract/add',
    contractId: '/contract/:contractId',
  },
  contractEventTypeConfig: {
    base: '/contractEventTypeConfig',
    list: '/contractEventTypeConfig/list',
    add: '/contractEventTypeConfig/add',
    contractEventTypeConfigId:
      '/contractEventTypeConfig/:contractEventTypeConfigId',
    prompt: {
      base: '/contractEventTypeConfig/:contractEventTypeConfigId/prompt',
      list: '/contractEventTypeConfig/:contractEventTypeConfigId/prompt/list',
      add: '/contractEventTypeConfig/:contractEventTypeConfigId/prompt/add',
      promptId:
        '/contractEventTypeConfig/:contractEventTypeConfigId/prompt/:promptId',
    },
  },
  contractGroup: {
    base: '/contractgroup',
    list: '/contractgroup/list',
    add: '/contractgroup/add',
    contractGroupId: '/contractgroup/:contractGroupId',
  },
  criticalEventType: {
    base: '/criticaleventtype',
    list: '/criticaleventtype/list',
    add: '/criticaleventtype/add',
    criticalEventTypeId: '/criticaleventtype/:criticalEventTypeId',
  },
  customer: {
    base: '/customer',
    list: '/customer/list',
    add: '/customer/add',
    customerId: '/customer/:customerId',
  },
  dashboard: {
    base: '/dashboard',
    list: '/dashboard/list',
    add: '/dashboard/add',
    dashboardId: '/dashboard/:dashboardId',
  },
  dashboardDataStudio: '/dashboard_datastudio',
  dashboardStandalone: '/dashboard_standalone',
  developerApiDocs: '/developerapidocs',
  documentTemplate: {
    base: '/documenttemplate',
    list: '/documenttemplate/list',
    add: '/documenttemplate/add',
    documentTemplateId: '/documenttemplate/:documentTemplateId',
  },
  documentation: {
    base: '/documentation',
    apiDocs: {
      base: '/documentation/apiDocs',
      client: '/documentation/apiDocs/client',
      developer: '/documentation/apiDocs/developer',
      integration: '/documentation/apiDocs/integration',
      tracking: '/documentation/apiDocs/tracking',
    },
    functional: '/documentation/functional',
    new: '/documentation/new',
    sops: '/documentation/sops',
  },
  celeryStatus: '/celeryStatus',
  driver: {
    base: '/driver',
    list: '/driver/list',
    add: '/driver/add',
    driverId: '/driver/:driverId',
  },
  driverDocument: {
    base: '/driverdocument',
    list: '/driverdocument/list',
    add: '/driverdocument/add',
    driverDocumentId: '/driverdocument/:driverDocumentId',
  },
  driverGroup: {
    base: '/drivergroup',
    list: '/drivergroup/list',
    add: '/drivergroup/add',
    driverGroupId: '/drivergroup/:driverGroupId',
  },
  driverRankIncentive: {
    base: '/driverrankincentive',
    list: '/driverrankincentive/list',
    add: '/driverrankincentive/add',
    driverRankIncentiveId: '/driverrankincentive/:driverRankIncentiveId',
  },
  event: {
    base: '/event',
    bulkClosing: '/event/bulkClosing',
    eventId: '/event/:eventId',
  },
  eventReview: {
    base: '/eventReview',
    eventId: '/eventReview/:eventId',
    review: '/eventReview/review',
    feedback: '/eventReview/feedback',
  },
  videoEvents: {
    base: '/videoEvents',
    list: '/videoEvents/list',
    eventId: '/videoEvents/:eventId',
    video: '/videoEvents/:eventId/video',
  },
  eventType: {base: '/eventType', list: '/eventType/list'},
  experimental: '/experimental/wysiwyg',
  feedback: '/feedback',
  groupExternal: {
    base: '/groupExternal',
    list: '/groupExternal/list',
    groupExternalID: '/groupExternal/:groupExternalID',
  },
  home: '/',
  masterRoute: {
    base: '/masterroute',
    list: '/masterroute/list',
    add: '/masterroute/add',
    masterRouteId: '/masterroute/:masterRouteId',
    leg: {
      add: '/masterroute/:masterRouteId/leg/add',
      masterRouteLegId: '/masterroute/:masterRouteId/leg/:masterRouteLegId',
    },
  },
  notificationConfig: {
    base: '/notificationconfig',
    list: '/notificationconfig/list',
    add: '/notificationconfig/add',
    notificationConfigId: '/notificationconfig/:notificationConfigId',
    task: {taskId: '/notificationconfig/:notificationConfigId/task/:taskId'},
  },
  notFound: '*',
  mapView: {
    base: '/mapView',
  },
  oidcCallback: '/oidc/login/callback',
  optimiser: {
    base: '/optimiser',
    list: '/optimiser/list',
    add: '/optimiser/add',
    optimiserId: '/optimiser/:optimiserId',
    trip: {
      base: '/optimiser/:optimiserId',
      add: '/optimiser/:optimiserId/trip/add',
      planningMasterTripId:
        '/optimiser/:optimiserId/trip/:planningMasterTripId',
    },
  },
  order: {
    base: '/order',
    list: '/order/list',
    add: '/order/add',
    recipient: '/order/recipient/:uuid',
    orderId: '/order/:orderId',
  },
  permission: {
    base: '/permission',
    list: '/permission/list',
    add: '/permission/add',
    permissionId: '/permission/:permissionId',
  },
  planningBoard: '/planningBoard',
  planningSkill: {
    base: '/planningskill',
    list: '/planningskill/list',
    add: '/planningskill/add',
    planningSkillId: '/planningskill/:planningSkillId',
  },
  product: {
    base: '/product',
    list: '/product/list',
    add: '/product/add',
    productId: '/product/:productId',
  },
  productGroup: {
    base: '/productgroup',
    list: '/productgroup/list',
    add: '/productgroup/add',
    productGroupId: '/productgroup/:productGroupId',
  },
  profile: {
    base: '/profile',
    list: '/profile/list',
  },
  purchaseOrder: {
    base: '/purchaseOrder',
    list: '/purchaseOrder/list',
    add: '/purchaseOrder/add',
    purchaseOrderId: '/purchaseOrder/:purchaseOrderId',
  },
  recipientUUID: '/recipient/:uuid',
  report: {
    base: '/report',
    list: '/report/list',
    add: '/report/add',
    reportId: '/report/:reportId',
    schedule: {
      base: '/report/:reportId/schedule',
      add: '/report/:reportId/schedule/add',
      reportScheduleId: '/report/:reportId/schedule/:reportScheduleId',
    },
  },
  role: {
    base: '/role',
    list: '/role/list',
    add: '/role/add',
    roleId: '/role/:roleId',
  },
  selfService: {
    base: '/selfService',
    external: '/selfService/external',
  },
  site: {
    base: '/site',
    list: '/site/list',
    add: '/site/add',
    nodeId: '/site/:nodeId',
    subNode: {
      add: '/site/:nodeId/subnode/add',
      subNodeId: '/site/:nodeId/subnode/:subNodeId',
    },
  },
  siteType: {
    base: '/siteType',
    list: '/siteType/list',
    add: '/siteType/add',
    siteTypeId: '/siteType/:siteTypeId',
  },
  speedTable: {
    base: '/speedtable',
    list: '/speedtable/list',
    add: '/speedtable/add',
    speedTableId: '/speedtable/:speedTableId',
  },
  support: '/support',
  task: {
    base: '/task',
    list: '/task/list',
    add: '/task/add',
    taskId: '/task/:taskId',
  },
  taskTemplate: {
    base: '/tasktemplate',
    list: '/tasktemplate/list',
    add: '/tasktemplate/add',
    taskTemplateId: '/tasktemplate/:taskTemplateId',
  },
  telematicsConfig: {
    base: '/telematicsconfig',
    list: '/telematicsconfig/list',
    add: '/telematicsconfig/add',
    telematicsConfigId: '/telematicsconfig/:telematicsConfigId',
  },
  transporter: {
    base: '/transporter',
    list: '/transporter/list',
    add: '/transporter/add',
    transporterId: '/transporter/:transporterId',
  },
  trip: {
    base: '/trip',
    list: '/trip/list',
    add: '/trip/add',
    masterTripId: '/trip/:masterTripId',
    stopsEdit: '/trip/:masterTripId/stops/edit',
    recipient: '/trip/recipient/:uuid',
  },
  tripDebrief: {
    base: '/tripdebrief',
    list: '/tripdebrief/list',
    masterTripId: '/tripdebrief/:masterTripId',
    stop: {
      tripStopId: '/tripdebrief/:masterTripId/stop/:tripStopId',
    },
    orderLine: {
      orderLineId: '/tripdebrief/:masterTripId/orderline/:orderLineId',
    },
  },
  tripDebriefConfig: {
    base: '/tripdebriefconfig',
    list: '/tripdebriefconfig/list',
    add: '/tripdebriefconfig/add',
    masterTripDebriefConfigId: '/tripdebriefconfig/:masterTripDebriefConfigId',
  },
  upload: '/upload',
  user: {
    base: '/user',
    list: '/user/list',
    add: '/user/add',
    userId: '/user/:userId',
  },
  userSettings: '/usersettings',
  vehicle: {
    base: '/vehicle',
    list: '/vehicle/list',
    add: '/vehicle/add',
    vehicleId: '/vehicle/:vehicleId',
  },
  vehicleBookingAvailability: '/vehicle_booking_availability',
  vehicleGroup: {
    base: '/vehiclegroup',
    list: '/vehiclegroup/list',
    add: '/vehiclegroup/add',
    vehicleGroupId: '/vehiclegroup/:vehicleGroupId',
  },
  vehicleType: {
    base: '/vehicletype',
    list: '/vehicletype/list',
    add: '/vehicletype/add',
    vehicleTypeId: '/vehicletype/:vehicleTypeId',
    compartment: {
      base: '/vehicletype/:vehicleTypeId/compartment',
      add: '/vehicletype/:vehicleTypeId/compartment/add',
      compartmentId: '/vehicletype/:vehicleTypeId/compartment/:compartmentId',
    },
    capacity: {
      base: '/vehicletype/:vehicleTypeId/capacity',
      add: '/vehicletype/:vehicleTypeId/capacity/add',
      capacityId: '/vehicletype/:vehicleTypeId/capacity/:capacityId',
    },
  },
  webhook: {
    base: '/webhook',
    list: '/webhook/list',
    add: '/webhook/add',
    webhookId: '/webhook/:webhookId',
  },
  webhookAuth: {
    base: '/webhookAuth',
    list: '/webhookAuth/list',
    add: '/webhookAuth/add',
    webhookAuthId: '/webhookAuth/:webhookAuthId',
  },

  driverWorkflow: {base: '/driverWorkflow', list: '/driverWorkflow/list'},
  vehicleWorkflow: {base: '/vehicleWorkflow', list: '/vehicleWorkflow/list'},
  orderWorkflow: {base: '/orderWorkflow', list: '/orderWorkflow/list'},
  orderLineWorkflow: {
    base: '/orderLineWorkflow',
    list: '/orderLineWorkflow/list',
  },

  workflowConfig: {
    base: '/workflowconfig',
    list: '/workflowconfig/list',
    add: '/workflowconfig/add',
    workflowConfigId: '/workflowconfig/:workflowConfigId',
  },
} as const;
