import {type RouteObject} from 'react-router-dom';

import EarlyWarningEvents from './pages/EarlyWarningEvents';
import EventFeedback from './pages/EventFeedback';
import ReviewEvent from './pages/ReviewEvent';
import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

export const eventsReviewRoutes: RouteObject[] = [
  {
    path: '',
    element: <EarlyWarningEvents />,
    loader: () => {
      document.title = 'Review Events';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Event Review'}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.eventReview.eventId,
      routes.eventReview.base,
    ),
    element: <ReviewEvent />,
    loader: () => {
      document.title = 'Review Event';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Event Review', path: routes.eventReview.base}],
      });
      return null;
    },
  },
  {
    path: routes.eventReview.review,
    element: <ReviewEvent />,
    loader: () => {
      document.title = 'Review Event';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Event Review', path: routes.eventReview.base}],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.eventReview.feedback,
      routes.eventReview.base,
    ),
    element: <EventFeedback />,
    loader: () => {
      document.title = 'Review Event';
      useBreadcrumbStore.setState({
        breadcrumbs: [{label: 'Event Review', path: routes.eventReview.base}],
      });
      return null;
    },
  },
];
