import {divIcon, ExtraMarkers} from 'leaflet';
import '@fortawesome/fontawesome-free/css/all.css';
import 'leaflet-extra-markers';
import 'leaflet-extra-markers/src/assets/less/leaflet.extra-markers.css';

export const VehicleLastSeenIcon = divIcon({
  className: 'vehicle_lastseen',
  iconSize: [25, 25],
});
export const VehicleActualIcon = divIcon({
  className: 'vehicle_actual',
  iconSize: [12, 12],
});
export const KnownStopIcon = divIcon({
  className: 'actual_stop known',
  iconSize: [12, 12],
});
export const ScheduledStopIcon = divIcon({
  className: 'actual_stop scheduled',
  iconSize: [12, 12],
});
export const IllegalStopIcon = divIcon({
  className: 'actual_stop illegal',
  iconSize: [12, 12],
});

export const EventIcon = ExtraMarkers.icon({
  icon: 'fa-exclamation-circle',
  markerColor: 'red',
  shape: 'star',
  prefix: 'fa',
});
export const ActualReplayIcon = ExtraMarkers.icon({
  icon: 'fa-truck',
  markerColor: 'red',
  shape: 'square',
  prefix: 'fa',
});
export const PlannedReplayIcon = ExtraMarkers.icon({
  icon: 'fa-truck',
  markerColor: 'blue',
  shape: 'square',
  prefix: 'fa',
});
export const DepotIcon = ExtraMarkers.icon({
  icon: 'fa-warehouse',
  markerColor: 'green',
  shape: 'circle',
  prefix: 'fa',
});
export const RestIcon = ExtraMarkers.icon({
  icon: 'fa-bed',
  markerColor: 'green',
  shape: 'circle',
  prefix: 'fa',
});
export const LoadingIcon = ExtraMarkers.icon({
  icon: 'fa-truck-loading',
  markerColor: 'green',
  shape: 'circle',
  prefix: 'fa',
});
export const BorderIcon = ExtraMarkers.icon({
  icon: 'fa-torii-gate',
  markerColor: 'green',
  shape: 'circle',
  prefix: 'fa',
});
export const FuelingIcon = ExtraMarkers.icon({
  icon: 'fa-gas-pump',
  markerColor: 'green',
  shape: 'circle',
  prefix: 'fa',
});
export const WaitingAreaIcon = ExtraMarkers.icon({
  icon: 'fa-hourglass-half',
  markerColor: 'green',
  shape: 'circle',
  prefix: 'fa',
});
export const WashbayIcon = ExtraMarkers.icon({
  icon: 'fa-soap',
  markerColor: 'green',
  shape: 'circle',
  prefix: 'fa',
});
export const InfoIcon = ExtraMarkers.icon({
  icon: 'fa-info-circle',
  markerColor: 'blue',
  shape: 'circle',
  prefix: 'fa',
});
