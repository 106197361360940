import {type Dispatch, memo, type SetStateAction} from 'react';
import {useNavigate} from 'react-router-dom';

import BiotechIcon from '@mui/icons-material/BiotechRounded';
import CloseIcon from '@mui/icons-material/CloseRounded';
import NotificationsNone from '@mui/icons-material/NotificationsNone';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import {styled} from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';

import {type EventsPaneTab} from './EventsPaneTabs';
import {routes} from '../../../../routes';
import {getNestedBorderRadius} from '../../../../theme/getNestedBorderRadius';
import {useAuthStore} from '../../../common/stores/authStore';
import {type ExpandedSearchForm} from '../../hooks/useSearchEvents';

interface EventsPaneSearchButtonsProps {
  activeTab: EventsPaneTab;
  expandedSearchForm: ExpandedSearchForm;
  setExpandedSearchForm: Dispatch<SetStateAction<ExpandedSearchForm>>;
}

export const EventsPaneSearchButtons = memo(function EventsPaneSearchButtons({
  activeTab,
  expandedSearchForm,
  setExpandedSearchForm,
}: EventsPaneSearchButtonsProps) {
  const navigate = useNavigate();
  const ssoToken = useAuthStore((state) => state.ssoToken);

  return (
    <Stack direction="row" spacing={1} p={1}>
      <ActionButton
        disabled={expandedSearchForm === 'advanced'}
        variant="contained"
        onClick={() => {
          setExpandedSearchForm('advanced');
        }}
        startIcon={<BiotechIcon />}
      >
        Advanced Search
      </ActionButton>
      {activeTab === 'Inbox' && ssoToken != null && (
        <ActionButton
          disabled={expandedSearchForm === 'basic'}
          variant="outlined"
          color="inherit"
          onClick={() => {
            navigate(routes.eventReview.base);
          }}
          startIcon={<NotificationsNone />}
        >
          Review Events
        </ActionButton>
      )}
      <Zoom in={expandedSearchForm !== 'none'}>
        <IconButton
          size="small"
          style={{marginLeft: 'auto'}}
          onClick={() => {
            setExpandedSearchForm('none');
          }}
        >
          <CloseIcon />
        </IconButton>
      </Zoom>
    </Stack>
  );
});

const ActionButton = styled(Button)(({theme}) => ({
  margin: theme.spacing(1),
  borderRadius: getNestedBorderRadius(theme, 1),
}));
