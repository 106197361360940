import {type ReactNode, useCallback, useEffect} from 'react';
import {AuthProvider, useAuth} from 'react-oidc-context';

import {clearOidcLocalStorage} from './helpers/clearOidcLocalStorage';
import {config} from '../../config';
import {routes} from '../../routes';
import {useAuthStore} from '../common/stores/authStore';
import {useEventStore} from '../common/stores/eventStore';
import {useUserStore} from '../common/stores/userStore';

export function AuthWrapper({children}: {children: ReactNode}) {
  return (
    <AuthProvider {...config.sso.oidcConfig}>
      <AuthWrapperHandler>{children}</AuthWrapperHandler>
    </AuthProvider>
  );
}

function AuthWrapperHandler({children}: {children: ReactNode}) {
  const resetAuthStore = useAuthStore((state) => state.reset);
  const resetUserStore = useUserStore((state) => state.reset);
  const ssoToken = useAuthStore((state) => state.ssoToken);
  const setSsoToken = useAuthStore((state) => state.setSsoToken);
  const setSubject = useAuthStore((state) => state.setSubject);
  const setAuthStatus = useAuthStore((state) => state.setAuthStatus);
  const eventsCountTimeoutRef = useEventStore(
    (state) => state.eventsCountTimeoutRef,
  );
  const auth = useAuth();

  const logout = useCallback(async () => {
    window.open(routes.auth.login, '_self');
    resetAuthStore();
    resetUserStore();
    if (eventsCountTimeoutRef != null) {
      clearTimeout(eventsCountTimeoutRef);
    }
    clearOidcLocalStorage();
    await auth.clearStaleState();
    await auth.revokeTokens();
    try {
      await auth.signoutSilent();
    } catch {
      await auth.revokeTokens();
    }
  }, [auth, eventsCountTimeoutRef, resetAuthStore, resetUserStore]);

  const attemptLogin = useCallback(async () => {
    setAuthStatus('authenticating');
    try {
      const response = await auth.signinSilent();
      if (response?.access_token == null) {
        await logout();
      }
    } catch (err) {
      await logout();
    }
  }, [auth, logout, setAuthStatus]);

  useEffect(() => {
    if (auth.user?.access_token != null) {
      setSsoToken(auth.user?.access_token);
      setSubject(auth.user.profile.sub);
    }
  }, [
    auth.user?.access_token,
    setSsoToken,
    auth.user?.profile?.sub,
    setSubject,
  ]);

  useEffect(() => {
    auth.startSilentRenew();

    const handleAccessTokenExpired = () => {
      void attemptLogin();
    };

    auth.events.addAccessTokenExpired(handleAccessTokenExpired);

    return () => {
      auth.stopSilentRenew();
      auth.events.removeAccessTokenExpired(handleAccessTokenExpired);
    };
  }, [attemptLogin, auth, ssoToken]);

  return children;
}
