import {lazy} from 'react';
import {redirect, type RouteObject} from 'react-router-dom';

import {routes} from '../../routes';
import {useBreadcrumbStore} from '../common/stores/breadcrumbsStore';

const GroupExternalList = lazy(async () => import('./pages/GroupExternalList'));
const GroupExternalEdit = lazy(async () => import('./pages/GroupExternalEdit'));

export const groupExternalRoutes: RouteObject[] = [
  {path: '', loader: () => redirect(routes.groupExternal.list)},
  {
    path: routes.getRelativePath(
      routes.groupExternal.list,
      routes.groupExternal.base,
    ),
    element: <GroupExternalList />,
    loader: () => {
      document.title = 'Group External Organisations';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {
            label: 'Group External Organisations',
            path: routes.groupExternal.list,
          },
        ],
      });
      return null;
    },
  },
  {
    path: routes.getRelativePath(
      routes.groupExternal.groupExternalID,
      routes.groupExternal.base,
    ),
    element: <GroupExternalEdit />,
    loader: () => {
      document.title = 'Edit Group External';
      useBreadcrumbStore.setState({
        breadcrumbs: [
          {
            label: 'Group External Organisations',
            path: routes.groupExternal.list,
          },
        ],
      });
      return null;
    },
  },
];
