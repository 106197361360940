import CameraAlt from '@mui/icons-material/CameraAlt';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {OverlayLoader} from '../../../../common/components/loader/OverlayLoader';

export function EventDetailsShimmer() {
  return (
    <Stack
      borderRadius="16px"
      bgcolor="white"
      mt="18px"
      width="100%"
      minHeight="120px"
    >
      <Stack
        width="100%"
        bgcolor="#EFEFEF"
        display="flex"
        direction="row"
        alignItems="center"
        columnGap="40px"
        p="8px 20px"
        sx={{
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
        }}
      >
        <CameraAlt />
        <Typography fontSize="16px" fontWeight={600}>
          EVENT DETAILS
        </Typography>
        <Typography fontSize="16px" fontWeight={600} ml="auto">
          00h 00m 00s
        </Typography>
      </Stack>
      <Stack
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
        position="relative"
      >
        <OverlayLoader loading />
      </Stack>
    </Stack>
  );
}
